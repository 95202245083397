import * as React from "react";
import { Row, Col, Card, Divider, Typography, Button, Tooltip } from "antd";
import {
  FacebookFilled,
  RedditOutlined,
  TwitterOutlined,
  YoutubeFilled,
  InstagramFilled,
  MailOutlined,
  LinkedinFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Link } from "gatsby";
import HeaderComp from "../components/header";
import "../components/layout.css";
import FooterComp from "../components/footer";

const Blog = () => {
  return (
    <>
      <HeaderComp />
      <div className="enterpriseBanner">
        <Row className="blogTitleRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h1 className="enterpriseTitle">
              Order Management: An Important Tool for Your E-Commerce Business
            </h1>
          </Col>
        </Row>
      </div>
      <div className="blogSubDiv">
        <p>
          E-Commerce or Electronic Commerce is the activity of electronically
          buying and selling of products over the internet. Electronic Commerce
          involves mobile commerce, electronic fund transfer, automated data
          collection, and inventory management.
        </p>
        <p>
          When you run an E-Commerce business, the orders you receive are the
          major sources for generating revenue. So you cannot overlook any of
          your incoming orders. Initially, when your business is new, it is easy
          to track orders manually but with the expansion in your business, it
          will be cumbersome to keep track.
        </p>
        <p>
          <span className="order-management">
            <Link to="/order-management" className="blogLink">
              <strong>Order management</strong>
            </Link>
          </span>{" "}
          refers to the process of receiving, tallying, and processing orders
          that come in on your E-Commerce website or marketplace.
        </p>
        <p>
          <strong>
            <em>
              Why is an Order Management System Important for Your E-Commerce
              Business?
            </em>
          </strong>
        </p>
        <ul className="BlogListUl">
          <li style={{ listStyleType: "disc" }}>
            Helps in streamlining order fulfillment process
          </li>
          <li style={{ listStyleType: "disc" }}>
            Room for fewer errors due to the automated system
          </li>
          <li style={{ listStyleType: "disc" }}>
            Single view for multiple channels.
          </li>
          <li style={{ listStyleType: "disc" }}>Continuous inventory sync</li>
          <li style={{ listStyleType: "disc" }}>Better customer service</li>
          <li style={{ listStyleType: "disc" }}>
            Easily accessible- order from anywhere, anytime
          </li>
          <li style={{ listStyleType: "disc" }}>
            Efficient, time and cost-saving
          </li>
          <li style={{ listStyleType: "disc" }}>Better control over data.</li>
        </ul>
        <p>
          <strong>
            <em>
              How an E-Commerce Order Management Software Helps You Optimize
              Your Processes?
            </em>
          </strong>
        </p>
        <p>
          With the advent of the internet, all commercial activities like
          buying, selling, booking has shifted online; therefore E-Commerce
          portals have gained more prominence over the years.
        </p>
        <p>
          In order to have a strong online presence, every company develops an
          e-commerce website or an application. The portal should push the buyer
          towards making a purchase by providing online support.
        </p>
        <p>
          Good Order Management requires a proper understanding of customer
          preferences and the ability of the collaborator to deliver the
          products efficiently.
        </p>
        <p>
          Finally, a solid OMS presents a comprehensive and connected solution.
        </p>
        <p>
          <strong>Our Expertise</strong>
        </p>
        <p>
          There is nothing worse than a poorly designed e-commerce application
          that fails to engage your customers. CW Solutions designs the best
          e-commerce applications that grab your customers’ attention, provides
          easy navigation and creates an experience that is well appreciated by
          them. Our unique applications are customized to meet your business
          requisites. We make sure that the graphic themes are consistent with
          the brand; that the shopping cart features are effortless and
          hassle-free; and that the user experience is one that will have them
          return to your e-commerce app over and over again.
        </p>
        <Divider style={{ margin: "10px 0px" }} />
        <p style={{ marginBottom: 0, fontSize: "14px" }}>June 2nd, 2020</p>
        <Divider style={{ margin: "10px 0px" }} />
        <Row className="platformRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h4 className="platformRowText">
              Share This Story, Choose Your Platform!
            </h4>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Row className="socialIconsRow">
              <div className="socialIconDiv">
                <Link
                  to="https://www.facebook.com/cwerpsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Facebook">
                    <FacebookFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://twitter.com/CwerpSolutions" target="_blank">
                  <Tooltip title="Twitter">
                    <TwitterOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Linkedin">
                    <LinkedinFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Reddit">
                    <RedditOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="WhatsApp">
                    <WhatsAppOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.youtube.com/channel/UC0wLykv1AkMd1ecUjRjbtwA"
                  target="_blank"
                >
                  <Tooltip title="Youtube">
                    <YoutubeFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Instagram">
                    <InstagramFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Email">
                    <MailOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
        <h3 className="platformRowText" style={{ paddingTop: 50 }}>
          Related Posts
        </h3>
        <Row>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
          >
            <Link to="/top-three-trends-that-will-shape-the-future-of-erp-business">
              <img src="https://cw.solutions/wp-content/uploads/2020/05/erp1-new.png" />
            </Link>
            <h2 className="orderManagementText">
              <Link
                to="/top-three-trends-that-will-shape-the-future-of-erp-business"
                className="orderManagementLink"
              >
                Top Three Trends that Will Shape the Future of ERP Business
              </Link>
            </h2>
            <p className="blogDate">
              May 7th, 2020 |{" "}
              <span className="blogDate">
                <Link
                  to="/top-three-trends-that-will-shape-the-future-of-erp-business"
                  className="orderManagementLink"
                >
                  0 Comments
                </Link>
              </span>
            </p>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
            xxl={{ span: 18 }}
          />
        </Row>
      </div>
      <FooterComp />
    </>
  );
};

export default Blog;
